<template>
  <div class="search">
    <v-container v-if="getProposals('header-banner').length > 0">
      <ProposalSlider :proposals="getProposals('header-banner')" />
    </v-container>
    <v-container v-if="getProposals('header-products').length > 0">
      <ProductListSlider
        v-for="proposal in getProposals('header-products')"
        class="py-2 force-mobile-product-card"
        :key="proposal.id"
        :proposal="proposal"
        :title="proposal.descr"
        :paginationClass="'swiper-pagination' + proposal.id"
      />
    </v-container>
    <v-container>
      <div class="d-flex align-center flex-row flex-wrap">
        <h1 class="search-title">
          Risultati ricerca per <span class="highlight"> {{ query }}</span>
        </h1>
        <strong
          class="text-subtitle-1 font-weight-bold ml-1 mt-1"
          v-if="count > 0"
        >
          ({{ count }} prodotti)
        </strong>
      </div>
      <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
      <p class="subtitle-2" v-if="realSearch">
        Avevi cercato
        <a class="real_search">{{ realSearch }}</a>
        ma non ha prodotto risultati.
      </p>
    </v-container>
    <ProductListGrid
      :query="query"
      :barcode="barcode"
      :parentCategoryId="parent_category_id"
      :key="key"
      mode="search"
      @productsCount="updateCount"
      @searchedText="updateRealSearch"
    />
    <v-container v-if="count == 0">
      <span v-if="promoFilter">
        Non sono presenti prodotti in promozione per la ricerca di
        <strong> {{ query }} </strong>.
      </span>
      <span v-if="!promoFilter">
        La ricerca di <strong>{{ query }}</strong> non ha prodotto risultati.
      </span>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
h1 {
  font-size: 28px;
  font-weight: normal;
  .highlight {
    font-weight: bold;
  }
}
.real_search {
  cursor: text;
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalSlider from "@/components/proposal/ProposalSlider.vue";

import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "SearchProducts",
  // props: ["query", "parent_category_id"],
  components: {
    ProductListGrid,
    ProposalSlider,
    ProductListSlider
  },
  data() {
    return {
      key: 0,
      count: -1,
      query: null,
      barcode: null,
      parent_category_id: null,
      realSearch: null
    };
  },
  mixins: [categoryMixins, deliveryReactive],
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter"
    })
  },
  methods: {
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = this.query;
      this.query = searchedText;
    },
    getQueryParams() {
      this.query = this.$route.query.q;
      this.barcode = this.$route.query.barcode;
      // this.queryLabel = this.$route.query.barcode
      //   ? this.$route.query.barcode
      //   : this.$route.query.q;
      this.parent_category_id = this.$route.query.parent_category_id;
      this.filter = this.$route.query.filter;
    },
    reload() {
      this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    this.getQueryParams();
    await this.resetFilters("q=" + this.query);
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      await this.resetFilters("q=" + this.query);
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  }
};
</script>
